import { Button, CustomFlowbiteTheme, TextInput } from "flowbite-react";
import { ChangeEvent, type FC } from "react";
import { HiSearch, HiOutlinePlus } from "react-icons/hi";

export interface DropdownSearchProp {
  placeholder: string;
  value?: string; // Accept a value prop to control the input
  children?: React.ReactNode; // This is the list of search results.
  showResults?: boolean;
  childrenAbsolute?: boolean;
  plain?: boolean;
  buttonText?: string;
  inputChanged?: (input: string) => void;
  buttonClick?: () => void;
}

const selectedTheme: CustomFlowbiteTheme = {
  textInput: {
    field: {
      input: {
        colors: {
          primary:
            "font-semibold cursor-pointer dark:text-white dark:bg-lgb-dark-background dark:text-white w-full",
        },
      },
    },
  },
};

export const DropdownSearch: FC<DropdownSearchProp> = (props) => {
  // Remove local state for the input value and use props.value instead
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    return props.inputChanged ? props.inputChanged(event.target.value) : null;
  };

  return (
    <div className="relative">
      <div className="flex gap-4 items-center align-center pb-4">
        <div className="grow">
          <TextInput
            theme={selectedTheme.textInput}
            color="primary"
            autoComplete="off"
            value={props.value || ""} // Use the value prop for the input field
            icon={HiSearch}
            id="search"
            name="search"
            placeholder={props.placeholder}
            required
            size={32}
            type="search"
            onChange={handleInputChange}
          />
        </div>
        {props.buttonText && (
          <div className={"whitespace-nowrap flex items-center justify-start"}>
            <Button
              className="bg-gray-500 dark:bg-gray-700"
              color="dark"
              onClick={props.buttonClick}
              type="submit"
            >
              <HiOutlinePlus className="mr-2 h-5 w-5" />
              {props.buttonText}
            </Button>
          </div>
        )}
      </div>

      {/* Render the search results */}
      {props.showResults && (
        <ul
          className={
            " mt-1 divide-y divide-gray-200 dark:divide-gray-700 z-10 dark:bg-lgb-dark-background dark:border-lgb-grey-600 dark:text-lgb-green-300  w-full" +
            (props.childrenAbsolute ? " bg-white absolute" : "") +
            (props.plain ? "" : " shadow-xl rounded-xl p-4")
          }
        >
          {props.children}
        </ul>
      )}
    </div>
  );
};
