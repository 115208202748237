import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import { Card } from "flowbite-react";
import { DeliveryArrow, DeliveryCompleted } from "../../atoms/Icons/Icons";
import NextUpListItem from "../../atoms/Utilities/NextUpListItem";
import { Order } from "../../../models";
import { IRouteTiming } from "../../../types";
import { EmptyState } from "../EmptyState";
import { useNavigate } from "react-router-dom";
import { OrderStatus } from "../../../types/order/IOrder";

export interface KpiStatusesProp {
  orders: Order[];
  isLoading: boolean;
  title: string;
  emptyTitle: string;
  emptyIcon: any;
  redirectPath: string;
}

export const ActivityLog: FC<KpiStatusesProp> = (props) => {
  const { t } = useTranslation(["orders", "common"]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedOrders, setSortedOrders] = useState(props.orders);
  const ordersPerPage = 6;

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = props.orders.slice(indexOfFirstOrder, indexOfLastOrder);

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  const handleNext = () => {
    if (currentPage < Math.ceil(props.orders.length / ordersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    setSortedOrders(
      currentOrders.sort((a, b) => {
        let dateA = a.route.at(0)?.stopDate;
        let dateB = b.route.at(0)?.stopDate;
        if (dateA && dateB) {
          return dateB.valueOf() - dateA.valueOf();
        } else return -1;
      }),
    );
  }, [currentOrders]);

  const getTimingText = (order: Order) => {
    const timing = order.route.at(0)?.timing as IRouteTiming | undefined;

    if (!timing) {
      return {
        text: "-",
        time: "-",
      };
    }

    switch (true) {
      case "before" in timing && "after" in timing:
        return {
          text: t("create.route_selection.before_and_after"),
          time:
            t("common:before") +
            " " +
            timing.before +
            ", " +
            t("common:after") +
            " " +
            timing.after,
        };
      case "before" in timing:
        return {
          text: t("create.route_selection.before"),
          time: timing.before,
        };
      case "after" in timing:
        return {
          text: t("create.route_selection.after"),
          time: timing.after,
        };
      case "earliest" in timing:
        return {
          text: t("create.route_selection.asap"),
          time: "",
        };
      case "latest" in timing:
        return {
          text: t("create.route_selection.latest"),
          time: "",
        };
      default:
        return {
          text: t("create.route_selection.before"),
          time: "",
        };
    }
  };
  return (
    <Card className="dark:bg-darkBlue dark:border-lgb-grey-600">
      {props.isLoading ? (
        <BeatLoader color="#7214FF" className="p-16 flex justify-center" />
      ) : (
        <>
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
              {props.title}
            </h3>
            {props.orders.length > 0 && (
              <a
                href={props.redirectPath}
                className="inline-flex items-center rounded-lg p-2 text-sm font-medium text-lgb-primary hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700"
              >
                {t("common:view_all")}
              </a>
            )}
          </div>
          {props.orders.length ? (
            <div className="h-full rounded-lg ">
              <div className="flow-root">
                <div className="flex justify-between py-4 text-sm font-medium text-lgb-grey-300">
                  <p>{t("common:details")}</p>
                  <p>
                    {props.orders.length &&
                    props.orders.at(0)?.status === OrderStatus.Completed
                      ? t("common:time")
                      : t("common:deadline")}
                  </p>
                </div>
                <ul>
                  {sortedOrders.map((order) => (
                    <li key={order.id}>
                      <NextUpListItem
                        redirectPath={props.redirectPath} //Temp until proper id in path so we can redirect to order details
                        icon={
                          order.status === OrderStatus.Completed ? (
                            <DeliveryCompleted />
                          ) : (
                            <DeliveryArrow />
                          )
                        } // Fallback to DeliveryArrow if status is not pickup. TODO once we have live data available
                        customerName={
                          order.customer.isCompany
                            ? (order.customer.name ?? "")
                            : order.customer.firstName +
                              " " +
                              order.customer.lastName
                        }
                        address={order.route.at(0)?.location.addressLine ?? ""} //TODO next stop address once live data is available
                        timeLabel={
                          order.status === OrderStatus.Completed
                            ? ""
                            : getTimingText(order).text
                        }
                        time={getTimingText(order).time ?? ""}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              {props.orders.length > ordersPerPage && (
                <div className="flex flex-col items-center border-t border-gray-200 pt-3 dark:border-gray-700 sm:pt-6">
                  <span className="text-sm text-gray-700 dark:text-gray-400">
                    {t("common:showing")}{" "}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {indexOfFirstOrder + 1}
                    </span>{" "}
                    {t("common:to").toLowerCase()}{" "}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {Math.min(indexOfLastOrder, props.orders.length)}
                    </span>{" "}
                    {t("common:of").toLowerCase()}{" "}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {props.orders.length}
                    </span>{" "}
                    {t("stats:orders.under_progress").toLowerCase()}
                  </span>

                  <div className="inline-flex mt-2 xs:mt-0">
                    <button
                      onClick={handlePrev}
                      disabled={currentPage === 1}
                      className="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <svg
                        className="w-3.5 h-3.5 me-2 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 5H1m0 0 4 4M1 5l4-4"
                        />
                      </svg>
                      {t("common:prev")}
                    </button>
                    <button
                      onClick={handleNext}
                      disabled={
                        currentPage ===
                        Math.ceil(props.orders.length / ordersPerPage)
                      }
                      className="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {t("common:next")}
                      <svg
                        className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <EmptyState
              icon={props.emptyIcon}
              title={props.emptyTitle}
              description={t("stats:orders.empty_ongoing_desc")}
              buttonText={t("orders:create.title")}
              buttonClick={() => navigate("orders/new")}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default ActivityLog;
