/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, type FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Label, Spinner, TextInput, Textarea } from "flowbite-react";
import { Location } from "../../../models";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { HiOutlinePlus } from "react-icons/hi";
import { ILocationPropertyValidated } from "../../../types";

export interface DialogProp {
  close: () => void;
}

export const CreateLocation: FC<DialogProp> = (props) => {
  const { t } = useTranslation(["orders", "validation", "modals"]);
  const { activeWorkspace } = useWorkspace();
  const [location, setLocation] = useState(Location.defaultLocation());
  const [invalidFields, setInvalidFields] = useState(
    {} as ILocationPropertyValidated,
  );
  const [hasValidated, setHasValidated] = useState(false);
  const [create, setCreate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  /**
   * Generic method to update the location object.
   *
   * @param key property name
   * @param val property value
   */
  const setProperty = (key: string, val: string | number) => {
    setLocation({ ...location, [key]: val } as Location);
    setInvalidFields({
      ...invalidFields,
      [key]: true,
    } as ILocationPropertyValidated);
  };

  /**
   * Need to validate user input before creating
   * the new location. Starts creation if location is valid. Returns a list
   * of invalid fields if something needs further changes.
   */
  const validateAndCreate = async () => {
    const validation = Location.validateLocation(location);
    if (validation.isValidLocation) {
      setProperty("workspaceId", activeWorkspace?.workspaceId || "");
      setCreate(true);
    } else {
      setHasValidated(true);
      setInvalidFields(validation.invalidFields);
    }
  };

  /**
   * Reacts to changes to location, as well as the flag which
   * defines if the user has clicked the create button. If the 'create'
   * flag is true, it means all fields have been validated and the location
   * is ready to be created.
   */
  useEffect(() => {
    const createLocation = async () => {
      setIsLoading(true);
      //TODO: Add loading on button/dialog to prevent user from clicking create several times.
      await Location.create(location).then((res) => {
        if (res) {
          /**
           * TODO: Success snackbar
           */
          props.close();
        } else {
          /**
           * TODO: "Something went wrong" - Snackbar
           */
        }
      });
      setIsLoading(false);
    };
    if (create) {
      createLocation();
    }
  }, [location, create, props]);

  return (
    <div>
      <p className="text-lgb-grey-600 text-base pb-5">
        {t("descriptions.new_location", { ns: "modals" })}
      </p>
      <div className="mb-4">
        <div className="mb-2 block">
          <Label
            htmlFor="display_name"
            value={t("create.route_selection.display_name")}
          />
        </div>
        <TextInput
          id="display_name"
          name="display_name"
          placeholder={t("create.route_selection.display_name_ph")}
          required
          type="text"
          color={
            hasValidated && invalidFields.displayName === false ? "failure" : ""
          }
          helperText={
            hasValidated && invalidFields.displayName === false ? (
              <>{t("validation:minimum_length").replace("{1}", "3")}</>
            ) : (
              ""
            )
          }
          onChange={(e) => setProperty("displayName", e.target.value)}
        />
      </div>
      <div className="mb-4">
        <div className="mb-2 block">
          <Label
            htmlFor="address"
            value={t("create.route_selection.address")}
          />
        </div>
        <TextInput
          id="address"
          name="address"
          placeholder={t("create.route_selection.address_ph")}
          required
          type="text"
          color={
            hasValidated && invalidFields.addressLine === false ? "failure" : ""
          }
          helperText={
            hasValidated && invalidFields.addressLine === false ? (
              <>{t("validation:minimum_length").replace("{1}", "5")}</>
            ) : (
              ""
            )
          }
          onChange={(e) => setProperty("addressLine", e.target.value)}
        />
      </div>

      <div className="mb-4 flex gap-4">
        <div className="flex-1">
          <div className="mb-2 block">
            <Label
              htmlFor="postal_code"
              value={t("create.route_selection.postal_code")}
            />
          </div>
          <TextInput
            id="postal_code"
            name="postal_code"
            placeholder={t("create.route_selection.postal_code_ph")}
            required
            type="text"
            color={
              hasValidated && invalidFields.postCode === false ? "failure" : ""
            }
            helperText={
              hasValidated && invalidFields.postCode === false ? (
                <>{t("validation:postal_code")}</>
              ) : (
                ""
              )
            }
            onChange={(e) => setProperty("postCode", e.target.value)}
          />
        </div>
        <div className="flex-1">
          <div className="mb-2 block">
            <Label htmlFor="city" value={t("create.route_selection.city")} />
          </div>
          <TextInput
            id="city"
            name="city"
            placeholder={t("create.route_selection.city_ph")}
            required
            type="text"
            color={
              hasValidated && invalidFields.city === false ? "failure" : ""
            }
            helperText={
              hasValidated && invalidFields.city === false ? (
                <>{t("validation:minimum_length").replace("{1}", "2")}</>
              ) : (
                ""
              )
            }
            onChange={(e) => setProperty("city", e.target.value)}
          />
        </div>
      </div>

      <div>
        <p className="text-base font-semibold dark:text-white pb-2">
          {t("create.driver_selection.summary.description_title")}
        </p>
        <Textarea
          className="h-full bg-white dark:bg-white box-border text-base"
          id="description"
          name="description"
          placeholder={t("create.driver_selection.summary.description_ph")}
          required
          onChange={(e) => setProperty("description", e.target.value)}
        />
      </div>
      <div className="pt-4 flex gap-4">
        {!isLoading && (
          <Button color="primary" onClick={() => validateAndCreate()}>
            <HiOutlinePlus className="mr-2 h-5 w-5 active:scale-95" />
            {t(
              "create.route_selection.create_location_dialog.add_button_confirm",
            )}
          </Button>
        )}
        {isLoading && (
          <Button color="primary">
            <Spinner size={"sm"} />
          </Button>
        )}
        <Button
          color="gray"
          onClick={() => props.close()}
          className="active:scale-95"
        >
          {t("create.route_selection.create_location_dialog.close_button")}
        </Button>
      </div>
    </div>
  );
};
