import { Button, CustomFlowbiteTheme, Tooltip } from "flowbite-react";
import type { FC } from "react";
import type { Placement } from "@floating-ui/core";

interface IDropdownItem {
  text: React.ReactNode;
  onClick: () => void;
}

interface DropdownButtonProps {
  items: IDropdownItem[];
  buttonText: string;
  buttonPrependIcon?: React.ReactNode;
  buttonAppendIcon?: React.ReactNode;
  placement?: Placement;
}

export const DropdownButton: FC<DropdownButtonProps> = (
  props: DropdownButtonProps,
) => {
  return (
    <Tooltip
      theme={selectedTheme}
      placement={props.placement}
      style="light"
      content={
        <div className="w-full divide-y divide-gray-100 px-1 dark:divide-gray-500 z-10">
          {props.items.map((x, index) => {
            return (
              <div
                key={index}
                className="fit cursor-pointer hover:text-lgb-primary dark:hover:text-lgb-blue-200"
                onClick={() => x.onClick()}
              >
                <div className="flex items-center space-x-4">
                  <div className="flex gap-2">{x.text}</div>
                </div>
              </div>
            );
          })}
        </div>
      }
    >
      <Button className="relative ml-4 " color="light" type="submit">
        {props.buttonPrependIcon && props.buttonPrependIcon}
        {props.buttonText}
        {props.buttonAppendIcon && props.buttonAppendIcon}
      </Button>
    </Tooltip>
  );
};

const selectedTheme: CustomFlowbiteTheme["tooltip"] = {
  base: "absolute z-10 inline-block rounded-lg text-sm font-medium shadow-lg",
  arrow: {
    base: "hidden",
  },
  style: {
    light:
      "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white",
  },
};

export default DropdownButton;
