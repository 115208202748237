/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import { Button } from "flowbite-react";
import { HiOutlinePlus, HiTrash, HiPencilAlt } from "react-icons/hi";
import {
  PageLayout,
  TableSkeleton,
  StandardTable,
  DialogForm,
  DriverModal,
} from "../../components";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { useDrivers, useCompanies, useVehicles } from "../../hooks";
import { useTranslation } from "react-i18next";
import { IDriver, ITableRow } from "../../types";
import { Driver, Company, Vehicle } from "../../models";
import { EmptyState } from "../../components";
import { emptyDriverIllustration } from "../../components/atoms/Icons/illustrations";

export const DriversPage: FC = function () {
  const [isUpsertModalOpen, openUpsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [driverData, setDriverData] = useState(Driver.default());
  const [workspaceCompanies, setWorkspaceCompanies] = useState([] as Company[]);
  const [vehicleList, setVehicleList] = useState([] as Vehicle[]);
  const { updateCacheKey } = useCache();
  const { activeWorkspace } = useWorkspace();
  const companiesQuery = useCompanies(activeWorkspace?.workspaceId ?? "");
  const vehiclesQuery = useVehicles(activeWorkspace?.workspaceId ?? "");
  const query = useDrivers(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "user", "driver"]);

  const isQueryLoading = useMemo(
    () =>
      query.isLoading ||
      query.isRefetching ||
      companiesQuery.isLoading ||
      companiesQuery.isRefetching ||
      vehiclesQuery.isLoading ||
      vehiclesQuery.isRefetching,
    [query, companiesQuery, vehiclesQuery],
  );

  const drivers = useMemo(() => query.data ?? [], [query]);

  const companies = useMemo(() => companiesQuery.data ?? [], [companiesQuery]);

  const vehicles = useMemo(() => vehiclesQuery.data ?? [], [vehiclesQuery]);

  useEffect(() => {
    if (!isQueryLoading) {
      setWorkspaceCompanies(companies);
    }
  }, [isQueryLoading, companies]);

  useEffect(() => {
    if (!isQueryLoading) {
      setVehicleList(vehicles);
    }
  }, [isQueryLoading, vehicles]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        drivers.map((driver) => {
          return {
            id: driver.id,
            cells: [
              {
                id: "firstname",
                children: driver.firstName,
                showOnSmallScreen: true,
              },
              {
                id: "lastname",
                children: driver.lastName,
                showOnSmallScreen: true,
              },
              {
                id: "email",
                children: driver.email,
              },
              {
                id: "phone",
                children: driver.phoneNumber,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setDriverData(driver);
                  openUpsertModal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setDriverData(driver);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, drivers]);

  return (
    <PageLayout>
      <div className="relative pb-20">
        {tableRows.length > 0 && (
          <div className="flex flex-col sm:flex-row justify-between items-start p-4">
            <div>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                {t("user:driverpage_title")}
              </h1>
              <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
                {t("user:drivers_description")}
              </p>
            </div>
            <Button
              color="light"
              onClick={() => {
                setDriverData(Driver.default());
                openUpsertModal(true);
              }}
              type="submit"
              className="mt-2 sm:mt-0 mb-4 sm:mb-0"
            >
              <HiOutlinePlus className="mr-2 h-5 w-5" />
              {t("user:new_driver_button")}
            </Button>
          </div>
        )}

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow">
                {isLoading ? (
                  <TableSkeleton />
                ) : tableRows.length === 0 ? (
                  // Render empty state when there are no table rows
                  <EmptyState
                    icon={emptyDriverIllustration}
                    title={t("driver:empty_state.title")}
                    description={t("driver:empty_state.description")}
                    buttonText={t("driver:empty_state.button_text")}
                    buttonClick={() => {
                      setDriverData(Driver.default());
                      openUpsertModal(true);
                    }}
                  />
                ) : (
                  <StandardTable
                    header={[
                      {
                        id: "firstname",
                        children: t("common:firstname"),
                        showOnSmallScreen: true,
                      },
                      {
                        id: "lastname",
                        children: t("common:lastname"),
                        showOnSmallScreen: true,
                      },
                      {
                        id: "email",
                        children: t("common:email"),
                      },
                      {
                        id: "phone",
                        children: t("common:phone"),
                      },
                    ]}
                    hasActions={true}
                    rows={tableRows}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DriverModal
        data={driverData}
        companies={workspaceCompanies}
        vehicles={vehicleList}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: IDriver) => {
          if (Driver.isNew(driverData))
            Driver.create(activeWorkspace?.workspaceId ?? "", formData);
          else Driver.update(driverData, formData);
          openUpsertModal(false);
          updateCacheKey();
        }}
        onCancel={() => openUpsertModal(false)}
      />

      <DialogForm
        title={t("user:delete_driver") + " " + driverData.firstName + "?"}
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        buttonConfirmColor="failure"
        confirmButton={() => {
          Driver.delete(driverData);
          openDeleteModal(false);
          updateCacheKey();
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />
    </PageLayout>
  );
};
